import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import "bootstrap/dist/css/bootstrap.min.css"

import { navigate } from "gatsby"
import { Container, Row, Col, Image } from "react-bootstrap"

import Header from "../../components/Header"
import Footer from "../../components/Footer"

import { useGlobalState } from "../../global/global"
import { getLanguageFile } from "../../global/langauge.helper"

import "./styles.scss"

import CUBES from "../../../assets/images/careerDetailCubes.png"
import * as queryString from "query-string"

const CareerDetailPage = ({ location }) => {
  const { page } = queryString.parse(location.search)

  const selectedCardName = page || "softwareEngineer_002"

  const [state] = useGlobalState()

  return (
    <Container fluid className="career-detail-main-container">
      <div className="application">
        <Helmet title={getLanguageFile(state.language).Title.career_detail}>
          <meta charSet="utf-8" />
        </Helmet>
      </div>
      <Header headerTextMode="dark" />
      <Container fluid className="career-detail-image-container">
        <Image className={"career-detail-image-background"} src={CUBES} />
      </Container>
      <Container
        fluid
        className="career-detail-first-section d-flex align-items-center"
      >
        <Container className="career-detail-introduction-container">
          <Row>
            <Col
              className={"career-detail-introduction-texts-col col-lg-8 col-10"}
            >
              <Row>
                <h2 className="career-detail-title">
                  {
                    getLanguageFile(state.language).CareerDetail[
                      selectedCardName
                    ]
                  }
                </h2>
              </Row>
              <Row>
                <p className="career-detail-description">
                  {
                    getLanguageFile(state.language).CareerDetail[
                      `${selectedCardName}FirstDescription`
                    ]
                  }
                </p>
              </Row>
              <Row>
                <h2 className="career-detail-sub-title">
                  {
                    getLanguageFile(state.language).CareerDetail[
                      `${selectedCardName}GeneralQualificationsTitle`
                    ]
                  }
                </h2>
              </Row>
              <Row>
                <p className="career-detail-description">
                  {
                    getLanguageFile(state.language).CareerDetail[
                      `${selectedCardName}GeneralQualifications`
                    ]
                  }
                </p>
              </Row>
              <Row>
                <h6 className="career-detail-sub-title">
                  {
                    getLanguageFile(state.language).CareerDetail[
                      `${selectedCardName}RequiredSkillsTitle`
                    ]
                  }
                </h6>
              </Row>
              <Row>
                <p className="career-detail-description">
                  {
                    getLanguageFile(state.language).CareerDetail[
                      `${selectedCardName}RequiredSkills`
                    ]
                  }
                </p>
              </Row>
              {getLanguageFile(state.language).CareerDetail[
                `${selectedCardName}PreferredTitle`
              ] ? (
                <>
                  {" "}
                  <Row>
                    <h6 className="career-detail-sub-title">
                      {
                        getLanguageFile(state.language).CareerDetail[
                          `${selectedCardName}PreferredTitle`
                        ]
                      }
                    </h6>
                  </Row>
                  <Row>
                    <p className="career-detail-description">
                      {
                        getLanguageFile(state.language).CareerDetail[
                          `${selectedCardName}Preferred`
                        ]
                      }
                    </p>
                  </Row>{" "}
                </>
              ) : (
                <></>
              )}

              <Row>
                <h6 className="career-detail-sub-title">
                  {
                    getLanguageFile(state.language).CareerDetail[
                      `${selectedCardName}PositionInformationTitle`
                    ]
                  }
                </h6>
              </Row>
              <Row>
                <p
                  style={
                    selectedCardName === "intern"
                      ? { fontWeight: "bold" }
                      : null
                  }
                  className="career-detail-description"
                >
                  {
                    getLanguageFile(state.language).CareerDetail[
                      `${selectedCardName}PositionInformation`
                    ]
                  }
                </p>
              </Row>
              {selectedCardName !== "intern" &&
              getLanguageFile(state.language).CareerDetail[selectedCardName] !==
                undefined ? (
                <Row>
                  <div
                    className="career-detail-button"
                    onClick={() =>
                      navigate("/job-application", {
                        state: { selectedCardName },
                      })
                    }
                  >
                    <h6 className="career-detail-button-text">
                      {getLanguageFile(state.language).CareerDetail.apply}
                    </h6>
                  </div>
                </Row>
              ) : (
                <Row>
                  <div className="career-detail-intern-button">
                    <h6 className="career-detail-button-text">
                      {getLanguageFile(state.language).CareerDetail.apply}
                    </h6>
                  </div>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </Container>
  )
}

CareerDetailPage.propTypes = {
  location: PropTypes.element,
  selectedCardName: PropTypes.string,
  cardName: PropTypes.string,
  id: PropTypes.string,
}

export default CareerDetailPage
